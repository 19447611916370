<template>
  <div class="turntable">
    <div class="turn-content">
      <img v-if="borderShow" src="@/assets/images/pdd/border2.png" class="border2">
      <img src="@/assets/images/pdd/content.png" :class="['content', isTransition ? 'transition' : '']"
        :style="`transform: rotate(${turnRotate}deg);`" @transitionend="transitionend">
      <img src="@/assets/images/pdd/border.png" class="border">
      <img src="@/assets/images/pdd/d.png" class="border3">
      <!-- <div class="star-wapper" v-for="item,index in 11" :key="index" :style="{transform: `rotate(${360/11*index}deg)`}">
          <img src="@/assets/images/pdd/star.png" class="star" :style="`animation-delay:${index}s`">
        </div> -->
      <div class="turn-button" @click="tabBtn">
        <img src="@/assets/images/pdd/button.png" alt="">
        <!-- v-html="$langKey($t('{num}次<br>抽奖'),{num:data.remain_turntable_count || 0})" -->
        <div class="btn-info">
          <div v-if="!amountShow">
            <p class="nums">{{ data.remain_turntable_count||0 }}</p>
            <p class="info1">{{$t("次")}}</p>
            <p class="info2">{{$t("抽奖")}}</p>
          </div>
          <div v-else>
            <div v-if="amount!=0" class="flex-box">
              +
              <animate-number :formatter="formatNumber" from="0" :to="amount" :key="amount" duration="1500" easing="easeOutQuad" from-color="#fff" to-color="#fff"
                style="font-size:20px;text-align:center;display:block;"></animate-number>
            </div>
            <div v-else class="reward-icon">
              <img src="@/assets/images/pdd/spin-4.png" alt="">
            </div>
          </div>


        </div>
      </div>
    </div>
    
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      turnRotate: 0,
      isTransition: false,
      baseRotate: 360 * 8,
      callBack: null,
      amountShow: false,
      amount: 0
    }
  },
  props: {
    data: {
      default: () => ({})
    },
    borderShow: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    tabBtn() {
      this.$emit("tabBtn")
    },
    run(callBack, amount) {
      this.data.remain_turntable_count -=1
      this.callBack = callBack
      if (this.isTransition) return
      this.isTransition = true
      this.$nextTick(() => {
        // this.turnRotate = this.baseRotate 
        this.turnRotate = this.baseRotate + (amount != 0 ? 0 : 135)
        setTimeout(() => {
          this.amount = amount
          this.amountShow = true
          setTimeout(()=>{
            this.amount=0
            this.amountShow = false
          },3000)
        }, 5000)
      })
    },
    transitionend() {
      this.callBack && this.callBack()
      this.isTransition = false
      this.$nextTick(() => {
        this.turnRotate = this.turnRotate - this.baseRotate
      })
    }
  }
}
</script>
  
  
<style scoped lang="less">
.turntable {
  width: 100%;
  padding-bottom: 100%;
  position: relative;
}

.turn-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.border2 {
  width: 117%;
  max-width: 120% !important;
  position: absolute;
  animation: 30s turn-border linear infinite;
  pointer-events: none;
}

@keyframes turn-border {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(1.1);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}


.border {
  width: 84%;
  position: absolute;
}

.border3 {
  width: 69%;
  position: absolute;
  animation: lamp-5cb94c3e .7s infinite;
}

@keyframes lamp-5cb94c3e {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  32.99% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  33% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  65.99% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }

  66% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }

  99.99% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
}

.content {
  width: 60%;
  position: absolute;
}

.transition {
  transition: all 5s;
}

.turn-button {
  position: absolute;
  width: 28%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -57%);
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.3;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (max-width:768px) {
    font-size: 12px;
  }
}

.btn-info {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-46%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  font-family: SWISSC;

  .nums {
    font-size: 32px;
    line-height: 32px;
    font-weight: bold;
  }

  .info1 {
    font-size: 9px;
  }

  .info2 {
    font-size: 12px;
    font-weight: bold;
  }
}

.star-wapper {
  position: absolute;
  height: 10%;
  width: 10%;
  z-index: 2;
  transform: rotate(45deg);
  pointer-events: none;
}

.star {
  width: 100%;
  position: absolute;
  z-index: 2;
  transform: translateY(310%);
  animation: 3s star linear infinite, 30s star-turn linear infinite;
  animation-delay: 1s;
  filter: blur(0) drop-shadow(0 0 0 rgb(243, 179, 179));
}

@keyframes star {
  0% {
    filter: blur(0) drop-shadow(0 0 0 rgb(243, 179, 179));
  }

  50% {
    filter: blur(0px) drop-shadow(0 0 10px rgb(255, 3, 3));
    transform: translateY(310%) scale(1.5);
  }

  100% {
    filter: blur(0) drop-shadow(0 0 0 rgb(243, 179, 179));
  }
}

@keyframes star-turn {
  0% {
    transform: translateY(310%) rotate(0deg);
  }

  100% {
    transform: translateY(310%) rotate(360deg);
  }
}

.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.reward-icon{
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  img{
    width: 100%;
    height: auto;
  }
}
</style>